import { Stack, Text } from '@mantine/core';
import classes from './Footer.module.css';


export default function Footer() {

    return (
        <footer className={classes.footer}>
            <Stack>
                <Text c="dimmed" size="sm" ta='center' mx='auto'>
                    © 2023 contrum.space. All rights reserved.
                </Text>
            </Stack>
        </footer>
    );
}