// notifications.ts

import { notifications } from '@mantine/notifications';

// Initialize Mantine notifications
const show = notifications.show;
const update = notifications.update;

// Notification types
export enum NotificationType {
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

// Function to show notifications
export const showNotification = (message: string, type: NotificationType, options?: any) => {
    switch (type) {
        case NotificationType.INFO:
            return show({ message, color: 'blue', ...options });
        case NotificationType.SUCCESS:
            return show({ message, color: 'green', ...options });
        case NotificationType.WARNING:
            return show({ message, color: 'orange', ...options });
        case NotificationType.ERROR:
            return show({ message, color: 'red', ...options });
        default:
            return show({ message, ...options });
    }
};

// Function to update notifications
export const updateNotification = (id, message: string, type: NotificationType, options?: any) => {
    switch (type) {
        case NotificationType.INFO:
            return update({ id, message, color: 'blue', ...options });
        case NotificationType.SUCCESS:
            return update({ id, message, color: 'green', ...options });
        case NotificationType.ERROR:
            return update({ id, message, color: 'red', ...options });
        default:
            return update({ id, message, ...options });
    }
};