import {
  Anchor,
  Group,
  Image,
  Loader,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconClipboardCopy,
  IconFlag,
  IconFocus,
  IconPlus,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { fetchData, fetchJumpsData, requestSetDestination } from "../../api";
import {
  NotificationType,
  showNotification,
  updateNotification,
} from "../../notification";

type SystemData = {
  id: number;
  system: string;
  region: string;
  security: number;
  distance: string;
  stargateJumps: number;
  jumps: number;
  rattingActivity: number;
  pvpActivity: number;
  jumpActivity: string;
};

type TableSelectionProps = {
  colHeight: string;
  systems: string[];
  system: string;
  sortOrder: string;
  focusSystem: React.Dispatch<React.SetStateAction<string>>;
  mapRendered: boolean;
};

const TableSelection: React.FC<TableSelectionProps> = ({
  colHeight,
  systems,
  system,
  sortOrder,
  focusSystem,
  mapRendered,
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [data, setData] = useState<SystemData[]>([]);
  const [isSorted, setIsSorted] = useState(false);

  const fetchDataAndSort = async () => {
    if (systems.length === 0) return;

    setLoaderVisible(true);
    try {
      const systemsData = await fetchData({ systems, origin: system });
      setIsSorted(false);
      const tableData = systemsData.map((system) => ({
        id: system.systemId,
        system: system.systemName,
        region: system.region,
        security: system.systemSecurity.toFixed(1),
        distance: `${system.distance.toFixed(2)}ly`,
        stargateJumps: system.stargateJumps,
        jumps: 0,
        rattingActivity: system.npcKills,
        pvpActivity: system.podShipKills,
        jumpActivity: system.jumps,
      }));

      setData(tableData);

      sortTable(tableData);
      getJumps(tableData);

      setLoaderVisible(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getJumps = async (systemsData: SystemData[]) => {
    if (systems.length === 0 || systems === undefined) return;

    try {
      const jumpsData = await fetchJumpsData({ systems, origin: system });

      const updatedData = systemsData.map((item) => ({
        ...item,
        stargateJumps:
          jumpsData.find((j) => j.system === item.system)?.jumps || 0,
      }));

      setIsSorted(false);
      setData(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sortTable = (dataToSort?: SystemData[]) => {
    const originalData = !dataToSort ? [...data] : dataToSort;

    if (originalData === undefined) return;

    let sortedData;

    switch (sortOrder) {
      case "Ratting Activity":
        sortedData = sortByKey(originalData, "rattingActivity");
        break;
      case "PvP":
        sortedData = sortByKey(originalData, "pvpActivity");
        break;
      case "Jumps in Last Hour":
        sortedData = sortByKey(originalData, "jumpActivity");
        break;
      case "Jumps from Origin":
        sortedData = sortByKey(originalData, "stargateJumps", true);
        break;
      default:
        // Default sorting if sortOrder is not recognized
        sortedData = originalData;
        break;
    }

    setIsSorted(true);
    setData(sortedData);
  };

  useEffect(() => {
    fetchDataAndSort();
  }, [system, systems]);

  useEffect(() => {
    if (!isSorted) {
      sortTable();
    }
  }, [data]);

  useEffect(() => {
    sortTable();
  }, [sortOrder]);

  const setDestination = async (id: number, addToEnd: boolean) => {
    const notif = showNotification("Setting Waypoint", NotificationType.INFO, {
      loading: true,
    });
    const success = await requestSetDestination(id, addToEnd);
    if (success) {
      updateNotification(notif, "Waypoint Set", NotificationType.SUCCESS, {
        loading: false,
      });
    } else {
      updateNotification(
        notif,
        "Error setting waypoint",
        NotificationType.ERROR,
        { loading: false }
      );
    }
  };

  const copyToClipboard = (val: string) => {
    navigator.clipboard.writeText(val);
    showNotification(`Copied ${val} to clipboard`, NotificationType.INFO);
  };

  const getColorBasedOnSecurity = (securityStatus: number) => {
    if (securityStatus >= 0.7) {
      return "green"; // High security color
    } else if (securityStatus >= 0.5) {
      return "yellow"; // Medium security color
    } else if (securityStatus >= 0.1) {
      return "orange"; // Medium security color
    } else {
      return "red"; // Low security color
    }
  };

  const rows = data
    ? data
        .filter((item) => item.stargateJumps !== -1)
        .map((item) => (
          <Table.Tr key={item.system}>
            <Table.Td>
              <Stack gap={2}>
                <Group gap={3}>
                  <Text size="sm" fw={500}>
                    {item.system}
                  </Text>
                  <Text
                    size="xs"
                    style={{ color: getColorBasedOnSecurity(item.security) }}
                  >
                    {item.security}
                  </Text>
                  <Tooltip label="Copy Name">
                    <IconClipboardCopy
                      size={15}
                      opacity={0.7}
                      onClick={() => {
                        copyToClipboard(item.system);
                      }}
                      cursor="pointer"
                    />
                  </Tooltip>
                  {mapRendered && (
                    <Tooltip label="Focus On Map">
                      <IconFocus
                        size={15}
                        opacity={0.7}
                        onClick={() => {
                          focusSystem(item.system);
                        }}
                        cursor="pointer"
                        color="orange"
                      />
                    </Tooltip>
                  )}
                </Group>
                <Text size="xs" fw={100}>
                  {item.region}
                </Text>
              </Stack>
            </Table.Td>
            <Table.Td>
              <Group gap={5}>
                <Tooltip
                  label="Set Destination"
                  onClick={() => setDestination(item.id, false)}
                >
                  <IconFlag size={15} color="#79d27a" cursor="pointer" />
                </Tooltip>
                <Tooltip
                  label="Add Waypoint"
                  onClick={() => setDestination(item.id, true)}
                >
                  <IconPlus size={15} color="green" cursor="pointer" />
                </Tooltip>
              </Group>
            </Table.Td>
            <Table.Td>
              {item.stargateJumps === -2 ? (
                <Loader size="xs" />
              ) : (
                item.stargateJumps
              )}
            </Table.Td>
            <Table.Td>{item.distance}</Table.Td>
            <Table.Td>{item.rattingActivity}</Table.Td>
            <Table.Td>{item.pvpActivity}</Table.Td>
            <Table.Td>{item.jumpActivity}</Table.Td>
            <Table.Td>
              <Group gap="xs">
                <Anchor
                  href={`https://zkillboard.com/system/${item.id}`}
                  target="_blank"
                >
                  <Tooltip label="Zkillboard">
                    <Image src="/zkill.png" h={20} w="auto"></Image>
                  </Tooltip>
                </Anchor>
                <Anchor
                  href={`https://evemaps.dotlan.net/map/${item.region}/${item.system}`}
                  target="_blank"
                >
                  <Tooltip label="Dotlan">
                    <Image src="/dotlan.jpeg" h={20} w="auto"></Image>
                  </Tooltip>
                </Anchor>
                <Anchor
                  href={`https://eveeye.com/?m=Radar&s=${item.system}`}
                  target="_blank"
                >
                  <Tooltip label="Eveye Radar">
                    <Image src="/eveeye.png" h={20} w="auto"></Image>
                  </Tooltip>
                </Anchor>
              </Group>
            </Table.Td>
          </Table.Tr>
        ))
    : [];

  return (
    <ScrollArea
      h={colHeight}
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      pos="relative"
    >
      <LoadingOverlay
        visible={loaderVisible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Table miw={800} verticalSpacing="sm" striped>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>System</Table.Th>
            <Table.Th></Table.Th>
            <Table.Th>Jumps</Table.Th>
            <Table.Th>Distance</Table.Th>
            <Table.Th>Rats Killed </Table.Th>
            <Table.Th>Ships Killed</Table.Th>
            <Table.Th>Ship Jumps</Table.Th>
            <Table.Th>External Links</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default TableSelection;

function sortByKey(
  data: SystemData[],
  key: string,
  ascending: boolean = false
) {
  return ascending
    ? data.slice().sort((a, b) => a[key] - b[key])
    : data.slice().sort((a, b) => b[key] - a[key]);
}
