import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Group,
  Loader,
  Mark,
  NativeSelect,
  NumberInput,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconInfoCircle, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { enterGiveaway, fetchSystemsData, searchSystem } from "../api";
import Graph from "../components/ContentGraph/Graph";
import ResultsTable from "../components/pages/content/Table";

const PRIMARY_COL_HEIGHT = rem(825);

const setQueryParam = async (key, value) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set(key, value);

  const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`;

  window.history.pushState(null, "", newUrl);
};

const removeQueryParam = (key) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete(key);

  const newUrl = urlSearchParams.toString()
    ? `${window.location.pathname}?${urlSearchParams.toString()}`
    : window.location.pathname;

  window.history.pushState(null, "", newUrl);
};

export function ContentFinder() {
  const urlSearchParams = new URLSearchParams(window.location.search);

  // Get specific parameter values
  const querySystem = urlSearchParams.get("system");
  const queryMode = urlSearchParams.get("mode");
  const rawParamRange = urlSearchParams.get("range") ?? "";
  const queryRange = rawParamRange !== "" ? parseInt(rawParamRange, 10) : null;
  const rawParamJumps = urlSearchParams.get("jumps") ?? "";
  const queryJumps = rawParamRange !== "" ? parseInt(rawParamJumps, 10) : null;
  const querySort = urlSearchParams.get("sort");
  const queryMap = urlSearchParams.get("map");

  const SECONDARY_COL_HEIGHT = `calc(${PRIMARY_COL_HEIGHT} / 2 - var(--mantine-spacing-md) / 2)`;

  const [renderMap, setRenderMap] = useState(
    queryMap !== null ? (queryMap === "true" ? true : false) : false
  );

  const [systemSearchText, setSystemSearchText] = useState(
    querySystem ? querySystem : ""
  );
  const [system, setSystem] = useState(querySystem ? querySystem : "");
  const [loading, setLoading] = useState(false);
  const [matchedSystemNames, setMatchedSystemNames] = useState<string[]>([]);

  const [rangeControl, setRangeControl] = useState(
    queryMode ? queryMode : "stargate"
  );

  const [jumpDriveRange, setJumpDriveRange] = useState(
    queryMode === "jump drive" && queryRange ? queryRange : 5
  );
  const [stargateJumps, setStargateJumps] = useState(
    queryMode === "stargate" && queryJumps ? queryJumps : 5
  );

  const [shipClass, setShipClass] = useState("Black Ops");
  const [jdcLevel, setJdcLevel] = useState(5);

  const [sortOrder, setSortOrder] = useState(
    querySort ? querySort : "Jumps from Origin"
  );

  const [systems, setSystems] = useState<string[]>([]);

  const [focusedSystem, setFocusedSystem] = useState<string>("");

  useEffect(() => {
    setQueryParam("mode", rangeControl);
    if (rangeControl === "stargate") {
      removeQueryParam("range");
      setQueryParam("jumps", stargateJumps);
    } else {
      removeQueryParam("jumps");
      setQueryParam("range", jumpDriveRange);
    }
  }, [rangeControl, jumpDriveRange, stargateJumps]);

  const getSystemSearchResults = async (system) => {
    setQueryParam("system", system);
    setMatchedSystemNames([]);
    if (system.trim().length === 0 || system.includes("@")) {
      setLoading(false);
    } else {
      setLoading(true);
      try {
        const matchedSystemNames = await searchSystem(system);
        setMatchedSystemNames(matchedSystemNames);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSystemInputChange = async (val: string) => {
    setSystemSearchText(val);
    getSystemSearchResults(val);
  };

  const onOptionSubmit = async (val) => {
    setSystem(val);
  };

  const handleStargateInputChange = (val: string | number) => {
    setQueryParam("jumps", val);
    removeQueryParam("range");
    if ((val as number) > 30) {
      notifications.show({
        title: "Jumps set to 30",
        message: "Stargate jumps cannot exceed 30 jumps",
      });
      setStargateJumps(30);
    }
    setStargateJumps(val as number);
  };

  useEffect(() => {
    let baseJumpDriveRange = 0;
    if (shipClass === "Black Ops") {
      baseJumpDriveRange = 4;
    } else if (shipClass === "Capital") {
      baseJumpDriveRange = 3.5;
    } else if (shipClass === "Super Capital") {
      baseJumpDriveRange = 3;
    }
    const jumpDriveRange =
      baseJumpDriveRange + baseJumpDriveRange * 0.2 * jdcLevel;
    setJumpDriveRange(jumpDriveRange);
    setQueryParam("range", jumpDriveRange);
    removeQueryParam("jumps");
  }, [shipClass, jdcLevel]);

  useEffect(() => {
    const fetchData = async () => {
      if (system === "") {
        return;
      }
      try {
        const systemsData = await fetchSystemsData({
          system,
          stargateJumps,
          jumpDriveRange,
          mode: rangeControl,
        });
        setSystems(systemsData);
      } catch (error) {
        notifications.show({
          message: `Error occurred fetching data for ${system}`,
          color: "red",
        });
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [system, stargateJumps, jumpDriveRange, rangeControl]);

  const handleShipClassChange = (event: any) => {
    const newShip = event.currentTarget.value;
    setShipClass(newShip);
  };

  const handleJdcLevelChange = (event: any) => {
    const newLevel = parseInt(event.currentTarget.value);
    setJdcLevel(newLevel);
  };

  const handleSortOrderChange = (val: string) => {
    setSortOrder(val);
    setQueryParam("sort", val);
  };

  const resultsTable = (
    <ResultsTable
      sortOrder={sortOrder}
      handleSortOrderChange={handleSortOrderChange}
      SECONDARY_COL_HEIGHT={SECONDARY_COL_HEIGHT}
      system={system}
      systems={systems}
      focusSystem={setFocusedSystem}
      mapRendered={renderMap}
    />
  );

  return (
    <Box>
      {/* <Stack
        bg="#9FD1DA77"
        w="100%"
        h={150}
        ta="center"
        justify="center"
        gap="xs"
      >
        <Title c="gold">🎉 Stratios Scope Syndication YC122 Skins 🎉</Title>
        <Title size="md">Giveaway ends on 1 May</Title>
        <Button size="md" onClick={enterGiveaway} mx="auto" variant="white">
          Enter Giveaway
        </Button>
      </Stack> */}
      <Helmet>
        <title>Content Finder | contrum.space</title>
        <meta property="og:title" content="Content Finder | contrum.space" />
        <meta
          property="og:description"
          content="Locate content in EVE Online with ease."
        />
        <meta property="og:image" content="/full-logo.png" />
        <meta property="og:url" content="https://maps.contrum.space" />
      </Helmet>
      <Container my="md" size="100%">
        <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="md">
          <Grid gutter="md">
            <Grid.Col>
              <Group justify="center" align="center">
                <Title>Content Finder</Title>
                <Switch
                  ml="auto"
                  defaultChecked
                  color="cyan"
                  labelPosition="right"
                  label="Render Map"
                  checked={renderMap}
                  thumbIcon={
                    renderMap ? (
                      <IconCheck
                        style={{ width: rem(12), height: rem(12) }}
                        color="green"
                        stroke={3}
                      />
                    ) : (
                      <IconX
                        style={{ width: rem(12), height: rem(12) }}
                        color="red"
                        stroke={3}
                      />
                    )
                  }
                  onChange={(event) => {
                    setQueryParam("map", event.currentTarget.checked);
                    setRenderMap(event.currentTarget.checked);
                  }}
                />
              </Group>
              <Stack mt="lg">
                <Autocomplete
                  value={systemSearchText}
                  data={matchedSystemNames}
                  onChange={handleSystemInputChange}
                  onOptionSubmit={onOptionSubmit}
                  rightSection={
                    loading ? (
                      <Loader size="1rem" color="cyan" type="oval" />
                    ) : null
                  }
                  label="Origin System"
                  placeholder="Jita"
                />

                <Container w="100%" p={0}>
                  <Group justify="center">
                    <Text mb="xs" mr="auto">
                      Consider systems within:
                    </Text>
                    {rangeControl !== "stargate" && (
                      <Tooltip
                        label="Only includes lowsec and nullsec systems"
                        ml="auto"
                      >
                        <IconInfoCircle color="yellow" />
                      </Tooltip>
                    )}
                  </Group>
                  <SegmentedControl
                    value={rangeControl}
                    onChange={setRangeControl}
                    radius="sm"
                    size="sm"
                    color="cyan"
                    data={[
                      { label: "Jump Drive Range", value: "jump drive" },
                      { label: "Stargate Jumps", value: "stargate" },
                    ]}
                    fullWidth
                  />
                </Container>

                {rangeControl === "jump drive" && (
                  <>
                    <NativeSelect
                      value={shipClass}
                      onChange={handleShipClassChange}
                      label="Ship Class"
                      data={["Black Ops", "Capital", "Super Capital"]}
                    />

                    <NativeSelect
                      label="Jump Drive Calibration"
                      value={jdcLevel}
                      onChange={handleJdcLevelChange}
                      data={[
                        { label: "Level 1", value: "1" },
                        { label: "Level 2", value: "2" },
                        { label: "Level 3", value: "3" },
                        { label: "Level 4", value: "4" },
                        { label: "Level 5", value: "5" },
                      ]}
                    />
                  </>
                )}

                {rangeControl === "stargate" && (
                  <>
                    <NumberInput
                      label="Stargate Jumps"
                      value={stargateJumps}
                      onChange={handleStargateInputChange}
                      allowDecimal={false}
                      allowNegative={false}
                      placeholder={`Enter stargate jumps from ${
                        system.length > 0 ? system : "origin system"
                      }`}
                    />
                  </>
                )}
              </Stack>
            </Grid.Col>
            <Grid.Col pt="lg">{renderMap && resultsTable}</Grid.Col>
          </Grid>
          {renderMap && (
            <Box
              p="xs"
              style={{
                border: "1px solid rgb(37, 38, 43)",
                borderRadius: "1rem",
              }}
              h={PRIMARY_COL_HEIGHT}
              pos="relative"
            >
              <Graph systems={systems} focusedSystem={focusedSystem} />
            </Box>
          )}
          {!renderMap && resultsTable}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
