import { Group, Loader, LoadingOverlay, Paper, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import Graph from 'react-graph-vis';
import { fetchGraphData } from '../../api';

type GraphViewProps = {
  systems: string[],
  focusedSystem: string;
}

function ContentGraph({ systems, focusedSystem }: GraphViewProps) {
  const [network, setNetwork] = useState<any>(null);
  const [graph, setGraph] = useState({
    nodes: [],
    edges: [],
  });

  const [loaderVisible, setLoaderVisible] = useState(false);
  const [stabilizing, setStabilizing] = useState(false);
  const [touched, setTouched] = useState(false);

  const [hoverEnabled, setHoverEnabled] = useState(false);
  const [pointerLocation, setPointerLocation] = useState({ x: 0, y: 0 });
  const [systemName, setSystemName] = useState('');

  useEffect(() => {
    setTouched(false);
    const fetchData = async () => {
      if (!systems || systems.length === 0) {
        return;
      }
      try {
        setLoaderVisible(true);
        const graphData = await fetchGraphData(systems);
        setLoaderVisible(false);
        setGraph(graphData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [systems]);

  useEffect(() => {
    if (network && focusedSystem !== '') {
      network!.focus(focusedSystem, {
        scale: 1.5,
        animation: {
          duration: 2000,
          easingFunction: 'easeInOutQuad'
        }
      });
    }
  }, [focusedSystem, network]);


  const options = {
    layout: {
      hierarchical: false,
      clusterThreshold: 500,
      randomSeed: 1,
    },
    nodes: {
      shape: 'box',
      scaling: {
        min: 10,
        max: 30,
      },
      font: {
        color: "#3d1f1e",
      }
    },
    edges: {
      color: '#ccc',
      arrows: {
        to: {
          enabled: false,
        }
      },
      smooth: {
        enabled: true
      }
    },
    physics: {
      solver: 'forceAtlas2Based',
      stabilization: {
        iterations: 200,
      }
    },
    repulsion: {
      nodeDistance: 500,
    },
    interaction: {
      editor: true,
      hover: true,
      zoomView: true,
      dragView: true,
      dragNodes: false
    },
    height: '100%',
  };

  const events = {
    select: function (event) {
      setTouched(true);
    },
    dragging: function (event) {
      setTouched(true);
    },
    zoom: function (event) {
      setTouched(true);
    },
    hoverNode: function ({ pointer, event, node }) {
      setTouched(true);
      setPointerLocation({
        x: event.pageX,
        y: event.pageY
      });
      setSystemName(node);
      setHoverEnabled(true);
    },
    blurNode: function () {
      setHoverEnabled(false);
    },
    stabilizationProgress: function (params) {
      console.log(params);
    },
    startStabilizing: function () {
      setStabilizing(true);
    },
    stabilized: function () {
      if (!touched) {
        network.fit();
      }
      setLoaderVisible(false);
      setStabilizing(false);
    }
  };

  const loadingChildren = <>
    <Loader type='dots' />
  </>

  return (
    <>
      <Group pos='absolute' opacity={stabilizing ? 1 : 0}>
        <Loader size='sm' />
        <Text size='sm'>Optimizing Layout</Text>
      </Group>
      <LoadingOverlay visible={loaderVisible} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} loaderProps={{ children: loadingChildren }} />
      <Paper pos='absolute' top={pointerLocation.y - 100} left={pointerLocation.x} h={hoverEnabled ? 250 : 0} w={hoverEnabled ? 150 : 0} bg='black' withBorder opacity={hoverEnabled ? 0.7 : 0} style={{
        zIndex: '20000'
      }}>
        <Text ta='center'>{systemName}</Text>
      </Paper>
      <Graph
        graph={graph}
        options={options}
        events={events}
        getNetwork={(network) => {
          setNetwork(network);
        }}
      />
    </>
  );
}

export default ContentGraph;
