import { Container, Divider, Mark, SegmentedControl, Stack, Text } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { fetchESIUpdateMinutesRemaining } from "../../../api";
import { NotificationType, showNotification, updateNotification } from "../../../notification";
import TableSelection from "../../Table/Table";

type ResultsTableProps = {
    handleSortOrderChange: (string) => void;
    SECONDARY_COL_HEIGHT: string;
    systems: string[],
    system: string;
    sortOrder: string;
    focusSystem: React.Dispatch<React.SetStateAction<string>>;
    mapRendered: boolean,
}

export default function ResultsTable({ sortOrder, handleSortOrderChange, SECONDARY_COL_HEIGHT, system, systems, focusSystem, mapRendered }: ResultsTableProps) {

    const [minutesRemaining, setMinutesRemaining] = useState<number | null>(null);
    const [value, rerender] = useToggle([1, 2]); // for rerendering table

    async function updateESIMinutesRemaining() {
        const minutes = await fetchESIUpdateMinutesRemaining();
        console.log(minutes, minutesRemaining);
        if (minutesRemaining && minutes > minutesRemaining) {
            refreshData();
        }
        setMinutesRemaining(minutes);
    }

    useEffect(() => {
        updateESIMinutesRemaining();

        // Set interval to update every 5 minutes
        const intervalId = setInterval(() => {
            updateESIMinutesRemaining();
        }, 60000); // 5 minutes in milliseconds

        // Clear interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [])

    const refreshData = async () => {
        const id = showNotification("Data will be updated automatically in 90 seconds", NotificationType.INFO, { loading: true, withCloseButton: true, autoClose: false, });
        setTimeout(() => {
            rerender();
            updateNotification(id, "Data updated", NotificationType.SUCCESS, { loading: false, autoClose: 5000 });
        }, 90000);  // 90 sec delay to allow backend to fetch the new data
    }

    return (
        <Stack>
            <Container w='100%' p={0}>
                <Text mb='xs'>Sort result by:</Text>
                <SegmentedControl
                    w='100%'
                    radius='sm'
                    size="sm"
                    color='cyan'
                    data={['Jumps from Origin', 'Ratting Activity', 'PvP', 'Jumps in Last Hour']}
                    value={sortOrder}
                    onChange={handleSortOrderChange}
                />
            </Container>
            {minutesRemaining && <Text size='xs' ta='right'>Next ESI data update in: <Mark px='xs' mx='xs' color='cyan'>{minutesRemaining} minutes</Mark></Text>}
            <Divider />
            <TableSelection key={value} colHeight={SECONDARY_COL_HEIGHT} systems={systems} system={system} sortOrder={sortOrder} focusSystem={focusSystem} mapRendered={mapRendered} />
            {systems && <Text ta='right'>Showing {systems.length} results</Text>}
        </Stack>
    )
}