import axios, { AxiosInstance } from 'axios';

const baseURL = "https://maps-api.contrum.space"

// Create an instance of Axios with a base URL
const axiosInstance: AxiosInstance = axios.create({
    baseURL,
});

// Optional: Add any default headers or interceptors if needed
// axiosInstance.defaults.headers.common['Authorization'] = 'Bearer YourAuthToken';

export default axiosInstance;
