// api.js

import { NotificationType, showNotification } from '../notification';
import axiosInstance from '../util/axios';

export const fetchProfile = async () => {
    try {
        const response = await axiosInstance.get('profile', {
            withCredentials: true,
        });
        return response.data.user;
    } catch (error) {
        // handleError(error, 'Error fetching user profile');
        return null;
    }
};

export const searchSystem = async (query) => {
    try {
        const response = await axiosInstance.post('/search', { query });
        return response.data.data.matchedSystemNames;
    } catch (error) {
        handleError(error, 'Error occurred searching');
    }
};

export const fetchSystemsData = async ({ system, stargateJumps, jumpDriveRange, mode }) => {
    try {
        const response = await axiosInstance.post('/systems', {
            system,
            stargateJumps,
            jumpDriveRange,
            mode,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data.data.systems;
    } catch (error) {
        handleError(error, `Error occurred fetching data for ${system}`);
    }
};

export const fetchJumpsData = async ({ systems, origin }) => {
    try {
        const jumpsDataResponse = await axiosInstance.post('jumps', {
            systems,
            origin,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const jumpsData = jumpsDataResponse.data.data.jumpsData;
        return jumpsData;
    } catch (error) {
        handleError(error, 'Error fetching jumps data');
        return [];
    }
};


export const fetchData = async ({ systems, origin }) => {
    try {
        const response = await axiosInstance.post('data', {
            systems,
            origin,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data.data.systemsData;
    } catch (error) {
        handleError(error, 'Error fetching data');
        return [];
    }
};

export const fetchJumps = async ({ systems, origin }) => {
    try {
        const response = await axiosInstance.post('jumps', {
            systems,
            origin,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data.data.jumpsData;
    } catch (error) {
        handleError(error, 'Error fetching jumps data');
        return [];
    }
};

export const fetchGraphData = async (systems) => {
    try {
        const response = await axiosInstance.post('graph', {
            systems,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data.data.graph;
    } catch (error) {
        handleError(error, 'Error fetching graph data');
        return { nodes: [], edges: [] };
    }
};

export const logout = async () => {
    try {
        const response = await axiosInstance.get('logout', {
            withCredentials: true,
        });
        response.status === 200 && showNotification("Logged Out Successfully", NotificationType.SUCCESS);
    } catch (error) {
        handleError(error, 'Error logging out');
    }
};
export const fetchESIUpdateMinutesRemaining = async () => {
    try {
        const response = await axiosInstance.get('system-data-refresh/minutes-remaining');

        return response.data.minutesRemaining;
    } catch (error) {
        handleError(error, 'Error fetching ESI update minutes remaining');
        return null;
    }
};


export const requestSetDestination = async (system, addToEnd) => {
    try {
        const response = await axiosInstance.post('set-destination', {
            system,
            addToEnd,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });

        return response.status === 200;
    } catch (error) {
        handleError(error, 'Error setting waypoint');
        return false;
    }
};

export const enterGiveaway = async () => {
    try {
        const response = await axiosInstance.post('giveaway', {}, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        });

        response.status === 200 && showNotification("Entered giveaway succesfully", NotificationType.SUCCESS);
    } catch (error) {
        showNotification("You must be logged in to enter the giveaway", NotificationType.WARNING);
        return false;
    }
};

const handleError = (error, message) => {
    if (error.response) {
        showNotification(`Server Error: ${error.response.data}`, NotificationType.ERROR);
        console.error('Server Error:', error.response.status);
        console.error('Server Response Data:', error.response.data);
        console.error('Server Response Headers:', error.response.headers);
    } else if (error.request) {
        showNotification('No response received from the server', NotificationType.ERROR);
        console.error('No response received:', error.request);
    } else{
        showNotification(`Request Setup Error: ${message}`, NotificationType.ERROR);
        console.error('Request Setup Error:', message);
    }
    console.error('Error Config:', error.config);
};