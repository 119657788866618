
import { AppShell, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { Error404 } from './pages/404';
import { ContentFinder } from './pages/content';

ReactGA.initialize("G-5YS16BE9M5");

export default function App() {
    return (
        <MantineProvider defaultColorScheme='dark' theme={{
            primaryColor: 'cyan',
            colors: {
                'cyan ': ['#e3fafc', '#c5f6fa', '#99e9f2', '#66d9e8', '#3bc9db', '#22b8cf', '#15aabf', '#1098ad', '#0c8599', '#0b7285'],
            },
        }}>
            <Notifications position="bottom-left" zIndex={1000} />
            <AppShell
                header={{ height: 105, offset: true }}
                footer={{ height: 120, offset: true }}

                padding="0"
            >
                <BrowserRouter>

                    <AppShell.Header>
                        <Header />
                    </AppShell.Header>

                    <AppShell.Main>
                        <Routes>
                            <Route path="/">
                                <Route index element={<ContentFinder />} />
                                {/* Using path="*"" means "match anything", so this route
                                acts like a catch-all for URLs that we don't have explicit
                                routes for. */}
                                <Route path="*" element={<Error404 />} />
                            </Route>
                        </Routes>
                    </AppShell.Main>

                    <AppShell.Footer pos='relative'>
                        <Footer />
                    </AppShell.Footer>
                </BrowserRouter>


            </AppShell>
        </MantineProvider>
    );
};