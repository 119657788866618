import {
  Anchor,
  Avatar,
  Box,
  Burger,
  Button,
  Divider,
  Group,
  HoverCard,
  Image,
  Menu,
  Stack,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import cx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchProfile, logout } from "../../api";
import classes from "./header.module.css";
import { IconLogout } from "@tabler/icons-react";

export default function Header() {
  const [user, setUser] = useState<{ profile: any } | null>(null);
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const theme = useMantineTheme();

  async function getUser() {
    const profile = await fetchProfile();
    if (profile !== null) {
      setUser({ profile });
    }
  }

  async function onLogout() {
    await logout();
    setUser(null);
  }

  useEffect(() => {
    getUser();
  }, []);

  const loginButton =
    user === null ? (
      <a
        href={`https://maps-api.contrum.space/auth`}
        target="_top"
        style={{
          textDecoration: "inherit",
        }}
      >
        <Image src="/eve-sso.png" h={40} w="auto" />
      </a>
    ) : (
      <Menu
        width={260}
        position="bottom-end"
        transitionProps={{ transition: "pop-top-right" }}
        onClose={() => setUserMenuOpened(false)}
        onOpen={() => setUserMenuOpened(true)}
        withinPortal
      >
        <Menu.Target>
          <Group gap={7}>
            <Avatar
              src={`https://images.evetech.net/characters/${
                user!.profile.CharacterID
              }/portrait`}
              alt={user!.profile.CharacterName}
              radius="xl"
              size={20}
            />
            <Text fw={500} size="sm" lh={1} mr={3}>
              {user!.profile.CharacterName}
            </Text>
            <Tooltip label="Logout">
              <IconLogout onClick={onLogout} cursor="pointer" />
            </Tooltip>
          </Group>
        </Menu.Target>
      </Menu>
    );

  return (
    <Box pb={120} h={60}>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          <Link to="/" className={classes.link}>
            <Image src="/full-logo.png" h={75} w="auto"></Image>
          </Link>
          <Divider orientation="vertical" />
          <Image src="/PartnerBadge.png" h={75} w="auto"></Image>
          <Stack ml="auto" gap={2}>
            <Text ta="center" fw="bold">
              More tools
            </Text>
            <Divider orientation="horizontal" />
            <Group ml="auto">
              <Anchor
                href="https://whmass.contrum.space"
                target="_blank"
                c="blue"
              >
                WH Mass Calculator
              </Anchor>
              <HoverCard width="auto" shadow="md">
                <HoverCard.Target>
                  <Anchor c="blue">Structure Watch</Anchor>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">Coming Soon</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>
          </Stack>
          <Divider orientation="vertical" />
          {loginButton}
          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            hiddenFrom="sm"
          />
        </Group>
      </header>
    </Box>
  );
}
